export const menuItems = [
  {
    path: "/",
    text: "home",
  },
  {
    path: "/about",
    text: "about",
  },
  {
    path: "/posts",
    text: "posts",
  },
  {
    path: "/contact",
    text: "contact",
  },
  {
    path: "/policy",
    text: "policy",
  },
]
